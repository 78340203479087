<template>
  <div>
    <div class="main-title">游戏列表</div>
    <div class="content-list-page page-content">
      <div class="margin-b-sm margin-l-sm">
        <span class="fl-l margin-l-sm" aria-label="解锁方式">
          <el-select v-model="searchData.unlock_type" clearable placeholder="解锁方式" size="mini">
            <el-option :value="1" label="顺序解锁"></el-option>
            <el-option :value="0" label="无"></el-option>
          </el-select>
        </span>

        <span class="fl-l margin-l-sm" aria-label="仅限会员">
          <el-select v-model="searchData.only_vip" clearable placeholder="是否推荐" size="mini">
            <el-option :value="1" label="是"></el-option>
            <el-option :value="0" label="否"></el-option>
          </el-select>
        </span>

        <span class="fl-l margin-l-sm" aria-label="状态">
          <el-select v-model="searchData.status" clearable placeholder="状态" size="mini">
            <el-option :value="0" label="下架"></el-option>
            <el-option :value="1" label="上架"></el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
        </span>

        <span class="fl-r">
          <router-link to="/game/add" class="router-link">
            <el-button type="primary" size="small">新增</el-button>
          </router-link>
        </span>
        <div class="table-content margin-t-sm">
          <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" v-loading="loading"
            style="width: 100%;background: #fff;">
            <el-table-column label="ID" property="id" width="80" align="center">
            </el-table-column>

            <el-table-column label="关卡名称" property="level" align="center">
            </el-table-column>

            <!-- <el-table-column label="图片" property="pic" align="center">
              <template slot-scope="scope">
                <el-image :src="path + scope.row.icon">
                </el-image>
              </template>
            </el-table-column> -->


            <el-table-column label="解锁方式" property="pic" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.unlock_type == 1?'顺序解锁':'无'}}</span>
              </template>
            </el-table-column>

            <el-table-column label="仅限会员" property="pic" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.only_vip == 1?'是':'否'}}</span>
              </template>
            </el-table-column>


            <el-table-column property="created_at" label="创建时间" align="center" width="180">
            </el-table-column>


            <el-table-column label="状态" property="status" align="center">
              <template slot-scope="scope">
                <div>
                  <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                    @change="updataStatus(scope.row.id, scope.row.status)" active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </div>
              </template>
            </el-table-column>


            <el-table-column width="300" label="操作">
              <template slot-scope="scope">
                <span class="margin-r-sm">
                  <el-link slot="reference" type="primary" @click="editAimActivity(scope.row.id)">编辑
                  </el-link>
                </span>
                <span class="margin-r-sm">
                  <el-link slot="reference" type="primary" @click="detailsAimActivity(scope.row.id)">详情
                  </el-link>
                </span>
                <span>
                  <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                    @confirm="deleted(scope.row.id)">
                    <el-link slot="reference" type="primary">删除</el-link>
                  </el-popconfirm>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="table-batch margin-l-sm">
          <span class="fl-l">
            <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize" @current-change="setPage"
              :total="total">
            </el-pagination>
          </span>
          <div style="clear: both"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../js/config'
export default {
  data() {
    return {
      //图片前缀
      path: config.imageUrl,
      searchData: {},
      loading: false,
      //分页
      page: 1,
      pageSize: 10,
      total: 0,
      tableData: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions('game', ['getGameList', 'gameDel']),
    ...mapActions('common', ['setStatus']),
    editAimActivity(row) {
      this.$router.push('/game/edit/' + row )
    },
    detailsAimActivity(row) {
      this.$router.push('/game/details/' + row+'/manage')
    },
    async deleted(row) {
      const { data, res_info } = await this.gameDel(row)
      if (res_info != 'ok') return
      this.getList()
      this.$message.success('删除成功！')
    },
    //状态改变
    async updataStatus(id, status) {
      let from = {
        id,
        "model": "Games\\GamesModel",
        status,
      }
      const { res_info } = await this.setStatus(from)
      if (res_info != 'ok') return
      this.$message.success('修改成功');
      await this.getList()
    },
    search() {
      this.page = 1
      this.getList()
    },
    //分页
    setPageSize(pageSize) {
      this.pageSize = pageSize
      this.getList()
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    async getList() {
      this.loading = true
      let from = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize
      }
      const { data } = await this.getGameList(from)
      console.log(data)
      this.tableData = data.list
      this.total = data.total
      this.loading = false
    },
  },
}
</script>

<style>

</style>